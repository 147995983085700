import AuthService from '@common/services/AuthService';

export interface Category {
	code: string;
	name: string;
	image: string;
	productCount: number;
	children: Category[];
	url: string;
}

export enum KioskType {
	FASO,
	KM,
	SMARTTERMINAL,
	LIVING,
	YOUTH,
	BATH
}

export function getKioskTypeName(kioskType: KioskType): string {
	switch (kioskType) {
		case KioskType.FASO:
			return 'FASO';
		case KioskType.KM:
			return 'KM';
		case KioskType.SMARTTERMINAL:
			return 'Smart Terminal';
		case KioskType.LIVING:
			return 'Wohnzimmer';
		case KioskType.YOUTH:
			return 'Jugendzimmer';
		case KioskType.BATH:
			return 'Badezimmer';
		default:
			return '';
	}
}

export interface EditableCategory {
	_id?: string;
	categoryName: string;
	categoryCode?: string;
	image: string;
	childCategories: EditableCategory[];
	sortPosition: number;
	kioskType?: KioskType;
	articleName?: string;
	subgroups?: string[];
	smartTerminalAdded?: boolean;
	smartTerminalDeleted?: boolean;
}

export interface CategoryRankingModel {
	ranking: number;
	category: string;
	topLevelCategory: string;
	type: string;
	revenue: number;
	visits: number;
	topHundred: number;
	rankingValue: number;
}

export interface CategoriesResponse {
	data: Category[];
	success: boolean;
	message?: string;
}

export function getCategoryName(categoryCode: string, categories: Category[]): string {
	for (const category of categories) {
		if (category.code === categoryCode) {
			return category.name;
		}
		const categoryName = getCategoryName(categoryCode, category.children);
		if (categoryName) {
			return categoryName;
		}
	}
	return '';
}

const authService = new AuthService();

export async function loadCategories(): Promise<Category[] | null> {
	const response = await authService.fetch<CategoriesResponse>('/api/categories', {
		method: 'GET'
	});

	if (response.success && response.data) {
		return response.data;
	} else {
		return null;
	}
}
